import Storage from "@/util/Storage";
import {delFriendComment, friendCommentGive, friendSucCommentList} from "@/Api/Friend";
import {friendSucCommentListType} from "@/Api/Friend/interfaceType";


/** 评论点赞 */
export function commentGive(id: string|number ) {
    let body = { commentId:id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendCommentGive({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 删除评论 */
export function DelComment(id: string|number ) {
    let body = { id }
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(delFriendComment({ userId,token },body).then(res=>{
        return res.data
    }))
}

/** 二级评论列表 */
export function commentList( body:friendSucCommentListType = { pageNo:1,pageSize:15,firstCommentId:"" } ) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(friendSucCommentList({ userId,token },body).then(res=>{
        return res.data
    }))
}
